import { Switch, BrowserRouter, Route, useParams } from 'react-router-dom';
import Home from './Routes/Home';
import Main from './Routes/Main';

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/main">
                    <Main />
                </Route>
                <Route path="/:id">
                    <Home />
                </Route>
                <Route path="/">
                    <Home />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
