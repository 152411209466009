import styled from 'styled-components';
import Logo from '../components/Logo';
import { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Header from '../components/Header';
import Background from '../assets/img/bg.svg';
import { FullPageScroll } from '../components/FullPage';
import MainView from '../view/MainView';
import IntroView from '../view/IntroView';
import { MansView } from '../view/MansView';
import { useParams } from 'react-router-dom';
import BusinessView from '../view/BusinessView';
import Portfolio from '../view/Portfolio';
import Contact from '../view/Contact';
import BusinessTwoView from '../view/BusinessTwoView';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsOpen(true), 5000);
    });
    return (
        <Wrap>
            <FullPageScroll>
                <Inner>
                    <Bg src={Background} />
                    <MainView />
                </Inner>
                <Inner>
                    <IntroView />
                </Inner>
                <Inner>
                    <MansView />
                </Inner>
                <Inner>
                    <BusinessView />
                </Inner>
                <Inner>
                    <BusinessTwoView />
                </Inner>
                <Inner>
                    <Portfolio />
                </Inner>
                <Inner>
                    <Contact />
                </Inner>
            </FullPageScroll>
            <AnimatePresence>{isOpen ? null : <Logo />}</AnimatePresence>
        </Wrap>
    );
};

export default Home;

// css
const Wrap = styled.div``;

const Inner = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`;

const SliderObject = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`;

const BlueBox = styled(SliderObject)`
    background-color: skyblue;
`;

const GreenBox = styled(SliderObject)`
    background-color: lightgreen;
`;

const GreyBox = styled(SliderObject)`
    background-color: grey;
`;

const OrangeBox = styled(SliderObject)`
    background-color: orange;
`;

const Bg = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-40%);
    opacity: 0.2;
    @media screen and (max-width: 786px) {
        left: 0%;
        top: 50%;
        transform: translateY(-40%);
        opacity: 0.2;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 50%;
    }
`;
