import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PortfolioList from '../components/PortfolioList';
import ImgModal from '../components/ImgModal';

interface IUserContents {
    id: string;
}

const Portfolio = () => {
    const [selectId, setSelectId] = useState<null | string>(null);
    const { id } = useParams<IUserContents>();
    return (
        <Wrapper>
            <PortfolioList selectedId={id !== undefined ? id : ''} />
            <AnimatePresence initial={true}>
                {id && <ImgModal projectId={id} />}
            </AnimatePresence>

            <Paragragh>
                "성장하는 하데스와 함께하세요. 여러분들과 같이 이 페이지를
                하나씩 추가해 나아갈 것입니다."
            </Paragragh>
        </Wrapper>
    );
};
export default Portfolio;

const Wrapper = styled(motion.div)`
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    padding-top: 90px;
    @media screen and (max-width: 786px) {
        padding: 90px 1rem 1rem;
    }
`;

const Paragragh = styled.div`
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2rem;
    font-weight: 500;
    @media screen and (max-width: 786px) {
        width: 100%;
        padding: 1rem;
    }
`;
