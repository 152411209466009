import { useRef, useState } from 'react';
import {
    Map,
    MapMarker,
    MapTypeControl,
    ZoomControl,
} from 'react-kakao-maps-sdk';
import styled from 'styled-components';
import useKakaoLoader from '../useKakaoLoader';
declare global {
    interface Window {
        kakao: any;
    }
}

const { kakao } = window;

const Contact = () => {
    useKakaoLoader();
    return (
        <Inner>
            <MapBox>
                <Map
                    className="map"
                    center={{
                        lat: 36.484787943380245,
                        lng: 127.30094548136854,
                    }}
                    style={{ width: '100%', height: '52rem' }}
                    draggable={false}
                    zoomable={false}
                >
                    <MapMarker
                        position={{
                            lat: 36.48512754983417,
                            lng: 127.30094548136854,
                        }}
                    ></MapMarker>
                    <MapInfo>
                        <ul>
                            <li>Hades</li>
                            <li>
                                주소: 세종특별자치시 한누리대로 2007,
                                411호(소담동, 퍼스트시티)
                            </li>
                        </ul>
                    </MapInfo>
                </Map>
            </MapBox>
            <Footer>Copyright 2024. Hades all rights reserved.</Footer>
        </Inner>
    );
};

export default Contact;

const Inner = styled.div`
    padding-top: 10rem;
    width: 100%;
    @media screen and (max-width: 786px) {
        padding: 90px 1rem 1rem;
    }
`;

const MapBox = styled.div`
    width: 100%;
    position: relative;
    border-radius: 0.8rem;
    overflow: hidden;
    @media screen and (max-width: 786px) {
        .map {
            height: 37.5rem !important;
        }
    }
`;

const Footer = styled.div`
    width: 100%;
    z-index: 11;
    position: relative;
    background-color: ${(props) => props.theme.bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;
    position: absolute;
    bottom: 0;
    left: 0;
`;

const MapInfo = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: white;
    border-radius: 0.8rem;
    z-index: 999;
    padding: 1.5rem;
    border: 1px solid #ddd;
    box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.32);
    ul li:first-child {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.4rem;
    }
    @media screen and (max-width: 786px) {
        top: 2rem;
        left: 1rem;
        right: 1rem;
    }
`;
