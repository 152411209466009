import { motion } from 'framer-motion';
import styled from 'styled-components';
import Background from '../assets/img/bg.svg';

const FirstTextVariants = {
    start: {
        opacity: 0,
        x: -30,
    },
    end: {
        opacity: 1,
        x: 0,
    },
};

const SecondTextVariants = {
    start: {
        opacity: 0,
        y: -30,
    },
    end: {
        opacity: 1,
        y: 0,
    },
};

const IntroView = () => {
    return (
        <WrapperBox>
            <Bg src={Background} />
            <TxtBox>
                <AniTxt
                    variants={FirstTextVariants}
                    initial="start"
                    whileInView="end"
                    transition={{
                        duration: 2,
                        type: 'tween',
                    }}
                >
                    "행복한 개발자들이 모여 만든 회사 <span>하데스</span>
                    입니다."
                </AniTxt>
                <AniTxtSub
                    variants={SecondTextVariants}
                    initial="start"
                    whileInView="end"
                    transition={{
                        duration: 1.5,
                        type: 'tween',
                    }}
                >
                    개발자들의 마음이 행복해야 최고의 결과물이 나올 수 있습니다.
                    <br />
                    행복한 기운이 고객에게 전달 될 수 있도록 최선을
                    다하겠습니다.
                </AniTxtSub>
            </TxtBox>
        </WrapperBox>
    );
};

export default IntroView;

const WrapperBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
`;

const TxtBox = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    min-height: 30rem;
    padding: 5rem;
    & * {
    }
    @media screen and (max-width: 786px) {
        padding: 2rem;
        justify-content: flex-start;
    }
`;

const Hr = styled.div`
    position: relative;
    margin: 0 2rem;
    &::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 30rem;
        top: -6.4rem;
        left: 0;
        background-color: #002c6b;
    }
`;

const AniTxt = styled(motion.p)`
    font-size: 2rem;
    font-weight: 500;
    text-align: right;
    margin-bottom: 3rem;
    span {
        color: #002c6b;
    }
    @media screen and (max-width: 786px) {
        font-size: 1.6rem;
        text-align: center;
    }
`;

const AniTxtSub = styled(motion.p)`
    font-size: 1.6rem;
    font-weight: 500;
    text-align: right;
    margin-bottom: 3rem;
    @media screen and (max-width: 786px) {
        font-size: 1.2rem;
        text-align: center;
    }
`;

const Bg = styled.img`
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translateY(-40%);
    opacity: 0.2;
    @media screen and (max-width: 786px) {
        left: 0%;
        top: 50%;
        transform: translateY(-40%);
        opacity: 0.2;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 50%;
    }
`;
