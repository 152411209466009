import styled from 'styled-components';
import logoImg from '../assets/img/Logo.svg';

type IHeader = {
    index?: string;
    currentIndex: number;
    onDotClick: (index: number) => void;
    name?: string;
};

const NameArr = [
    {
        index: 0,
        name: 'HOME',
    },
    {
        index: 1,
        name: '회사소개',
    },
    {
        index: 2,
        name: '조직구성',
    },
    {
        index: 3,
        name: '사업소개',
    },
    {
        index: 4,
        name: '',
    },
    {
        index: 5,
        name: '포트폴리오',
    },
    {
        index: 6,
        name: '오시는길',
    },
];

type ILi = {
    index: number;
    currentIndex: number;
    onClick: (index: number) => void;
    name: string;
};
const Li: React.FC<ILi> = ({ index, currentIndex, name, onClick }) => {
    const selected = index === currentIndex;
    return (
        <li
            style={{ color: selected ? '#023ECF' : 'black' }}
            onClick={() => onClick(index)}
        >
            {name}
        </li>
    );
};

const Header: React.FC<IHeader> = ({ currentIndex, onDotClick }) => {
    return (
        <Head>
            <Inner>
                <LogoBox>
                    <img src={logoImg} alt="" />
                </LogoBox>
                <Nav>
                    {NameArr.map((item, index) => (
                        <Li
                            key={index}
                            index={index}
                            currentIndex={currentIndex}
                            onClick={onDotClick}
                            name={item.name}
                        ></Li>
                    ))}
                </Nav>
            </Inner>
        </Head>
    );
};

export default Header;

const Head = styled.header`
    position: fixed;
    top: 10px;
    left: 0%;
    width: 100%;

    z-index: 100;
    @media screen and (max-width: 786px) {
        padding: 0 1rem;
    }
`;

const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 25px 27px;
    overflow: hidden;
    border-radius: 40px;
    background: rgba(255, 255, 255, 0.18);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
`;

const LogoBox = styled.div`
    display: flex;
    width: 160px;
    img {
        max-width: 100%;
    }
`;

const Nav = styled.ul`
    display: flex;
    margin-right: 20px;
    gap: 25px;
    align-items: center;
    padding-top: 2px;
    li {
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        :nth-child(5) {
            display: none;
        }
    }
    @media screen and (max-width: 786px) {
        display: none;
    }
`;
