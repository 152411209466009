import { motion } from 'framer-motion';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PortfolioImg } from '../userContents';
import { IImgProps } from './PortfolioList';

const ImgModal = ({ projectId }: { projectId: string }) => {
    const data = PortfolioImg.find((v: IImgProps) => v.projectId === projectId);
    const navi = useHistory();
    const toggleLeaving = () => {
        navi.push('/');
    };
    return (
        <Overlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.15 } }}
            onClick={toggleLeaving}
        >
            <Box>
                <Link to="/">X</Link>
                <FlexBox>
                    <p>
                        <span>{data?.project}</span>
                    </p>
                </FlexBox>
                <img src={data?.imgMain} alt="" />
            </Box>
        </Overlay>
    );
};

export default ImgModal;

const Overlay = styled(motion.div)`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    a {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: ${(props) => props.theme.bgColor};
        font-size: 1.5rem;
    }
    @media screen and (max-width: 786px) {
        padding: 1rem;
    }
`;

const Box = styled(motion.div)`
    overflow: auto;
    position: relative;
    background-color: #333;
    border-radius: 20px;
    max-height: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.06);
    padding: 1rem 1rem;
    p {
        color: ${(props) => props.theme.bgColor};
        margin-bottom: 1rem;
    }
    p span {
        font-size: 1.5rem;
        font-weight: bold;
    }
    img {
        border: 1px solid #fff;
        border-radius: 0.8rem;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
    }
    @media screen and (max-width: 786px) {
        img {
            max-width: 100%;
        }
    }
`;

const FlexBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    p:last-child {
        font-size: 1.1rem;
    }
`;
