import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PortfolioImg } from '../userContents';

export interface IImgProps {
    imgPath: string;
    projectId: string;
    project: string;
    projectCustomer: string;
    imgMain: string;
    isSelected?: boolean;
}

const Item = ({ imgPath, project, projectCustomer, projectId }: IImgProps) => {
    return (
        <Box className={projectId}>
            <Link to={`/${projectId}`}>
                <p>
                    <span>{project}</span>
                </p>
                <img src={imgPath} alt="" />
            </Link>
        </Box>
    );
};

const PortfolioList = ({ selectedId }: { selectedId: string }) => {
    return (
        <Grid>
            {PortfolioImg.map((item: IImgProps) => (
                <Item
                    key={item.projectId}
                    {...item}
                    isSelected={item.projectId === selectedId}
                />
            ))}
        </Grid>
    );
};

export default PortfolioList;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    //grid-template-rows: repeat(2, 1fr);
    width: 50vw;
    border-radius: 0.8rem;
    overflow: hidden;
    margin-bottom: 10rem;
    @media screen and (max-width: 786px) {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Box = styled(motion.div)`
    position: relative;
    height: 230px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    a {
        display: flex;
        width: 100%;
        height: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: all 0.2s;
        }
        :after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            transition: all 0.3s;
        }
        &:hover p {
            transition: all 0.2s;
            transform: scale(1.2);
        }
        &:hover img {
            transform: scale(1.2);
        }
    }
    p {
        color: ${(props) => props.theme.bgColor};
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 11;
    }
    p span {
        font-size: 1.5rem;
        font-weight: bold;
    }
    @media screen and (max-width: 786px) {
        height: 130px;
    }
`;
