import Man11 from './assets/img/man11.svg';
import Man22 from './assets/img/man22.svg';
import Man33 from './assets/img/man33.svg';
import Man44 from './assets/img/man44.svg';
import Doggabi from './assets/img/bg_x3.png';
import BluezoneLogo from './assets/img/bluezoneLogo.png';
import BluezoneDrive from './assets/img/bluezone_drive.png';
import RecipeBank from './assets/img/recipeBank.png';
import RecipeBankMain from './assets/img/recipebank_main.png';
import BluezoneMain from './assets/img/bluezone_main.png';
import DoggabiMain from './assets/img/doggabi_main.png';

export const userContents = [
    {
        name: '김영일',
        grade: 'PM',
        role: '프로젝트 관리, 영업, 서버 관리, 백엔드 SM',
        img: Man11,
        id: 'CEO',
    },
    {
        name: '이은성',
        grade: '프론트엔드',
        role: '영업, 기획, 디자인, 프론트엔드 개발',
        img: Man22,
        id: 'Director',
    },
    {
        name: '박재현',
        grade: '개발',
        role: '영업, 백엔드 개발',
        img: Man33,
        id: 'DirectorDevoloper',
    },
    {
        name: '이현수',
        grade: '개발',
        role: '프론트엔드 / 백엔드 개발',
        img: Man44,
        id: 'Developer',
    },
];

export const PortfolioImg = [
    {
        imgPath: Doggabi,
        projectId: 'doggabi',
        project: '꿈도깨비',
        projectCustomer: '교보 + 초록우산',
        imgMain: DoggabiMain,
    },
    {
        imgPath: BluezoneLogo,
        projectId: 'bluezone',
        project: '블루존클라우드',
        projectCustomer: 'sanco',
        imgMain: BluezoneMain,
    },
    {
        imgPath: RecipeBank,
        projectId: 'recipebank',
        project: '레시피뱅크',
        projectCustomer: '블루레시피',
        imgMain: RecipeBankMain,
    },
];
