import { letterName, LetterSet } from '../constants';

interface IPath {
    width: string;
    height: string;
    color?: string;
    letter: letterName;
}

const LetterSvg = (props: IPath) => {
    return (
        <svg
            width={props.width ? props.width : '40'}
            height={props.height ? props.height : '40'}
            fill={props.color}
            viewBox={LetterSet[props.letter].viewBox}
        >
            <path d={LetterSet[props.letter].path} />
        </svg>
    );
};

export default LetterSvg;
