import { motion } from 'framer-motion';
import styled from 'styled-components';
import { IUserContents } from './List';
import { userContents } from '../userContents';
import { Link, useHistory } from 'react-router-dom';

const MansModal = ({ id }: { id: string }) => {
    //const { name } = userContents.find((v: IUserContents) => v.id === id);
    const data = userContents.find((v: IUserContents) => v.id === id);
    const navi = useHistory();
    const toggleLeaving = () => {
        navi.push('/');
    };
    return (
        <Overlay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.15 } }}
            onClick={toggleLeaving}
        >
            <Box layoutId={id}>
                <Link to="/">X</Link>
                <img src={data?.img} alt="" />
                <div>
                    <p>
                        <span>{data?.grade}</span>
                        <span>{data?.name}</span>
                    </p>
                    <p>{data?.role}</p>
                </div>
            </Box>
        </Overlay>
    );
};

export default MansModal;

const Overlay = styled(motion.div)`
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    a {
        position: absolute;
        top: 2rem;
        right: 2rem;
        color: ${(props) => props.theme.bgColor};
        font-size: 2rem;
    }
    @media screen and (max-width: 786px) {
        padding: 1rem;
    }
`;

const Box = styled(motion.div)`
    position: relative;
    width: 600px;
    background-color: #333;
    border-radius: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.06);
    padding: 2rem;
    gap: 1rem;
    p {
        color: ${(props) => props.theme.bgColor};
        margin-bottom: 1rem;
        @media screen and (max-width: 786px) {
            margin-bottom: 8px;
            :last-child {
                margin-bottom: 0;
            }
        }
    }
    p span {
        font-size: 1.5rem;
    }
    p span:first-child {
        font-size: 1rem;
    }
    p span:last-child {
        font-weight: 700;
        margin-left: 1rem;
    }
    div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        p:last-child {
            font-size: 1.1rem;
        }
    }
    img {
        width: 15rem;
        padding: 1rem;
        border: 1px solid #fff;
        border-radius: 0.8rem;
    }
    @media screen and (max-width: 786px) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        img {
            width: 8rem;
            padding: 0;
        }
    }
`;
