import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const MainTextVariants = {
    start: {
        opacity: 0,
        y: -30,
    },
    end: {
        opacity: 1,
        y: 0,
    },
};

const MainView = () => {
    const [done, setDone] = useState(false);
    const baseTxt = '"행복을 만들고 행복을 전달합니다."';
    const count = useMotionValue(0);
    const rounded = useTransform(count, (latest) => Math.round(latest));
    const displayTxt = useTransform(rounded, (latest) =>
        baseTxt.slice(0, latest),
    );
    useEffect(() => {
        const controls = animate(count, baseTxt.length, {
            type: 'tween',
            delay: 5,
            duration: 3,
            ease: 'easeInOut',
            onComplete: () => {
                setDone(true);
            },
        });
        return controls.stop;
    }, []);
    return (
        <Inner>
            <Title
                variants={MainTextVariants}
                initial="start"
                animate="end"
                transition={{
                    delay: 5,
                    duration: 2,
                    type: 'tween',
                }}
            >
                Hades
            </Title>
            <Paragragh>
                <span>Ha</span>ppy <span>De</span>veloper
                <span>s</span>
            </Paragragh>

            <MainTxt>{displayTxt}</MainTxt>
        </Inner>
    );
};

export default MainView;

const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
`;

const MainTxt = styled(motion.p)`
    font-size: 2.4rem;
    font-weight: 400;
    position: absolute;
    bottom: 5rem;
    width: 100%;
    transform: translateX(-50%);
    left: 50%;
    text-align: center;
    font-family: 'East Sea Dokdo', sans-serif;
    @media screen and (max-width: 786px) {
        font-size: 1.6rem;
    }
`;

const Title = styled(motion.h3)`
    font-size: 7rem;
    font-weight: 700;
    line-height: 0.7;
    @media screen and (max-width: 786px) {
        font-size: 5rem;
    }
`;
const Paragragh = styled.p`
    font-size: 2.5rem;
    font-weight: 400;
    letter-spacing: -0.55px;
    span {
        color: ${(props) => props.theme.signatureColor};
    }
    @media screen and (max-width: 786px) {
        font-size: 2rem;
    }
`;
