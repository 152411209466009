import styled from 'styled-components';
import Town from '../assets/img/townApp.png';
import businessBg from '../assets/img/businessBg.png';

const BusinessTwoView = () => {
    return (
        <Inner>
            <Bg src={businessBg} />
            <FlexibleBox>
                <RightCon>
                    <p>‘마을회관’ 앱 서비스</p>
                    {/* <p>
                        시골 마을 어르신들의 사고 위험 예방을 위한 부녀회장 중심{' '}
                        <br />
                        마을 주민 소통 어플리케이션// 초고령화 시대로 접어드는{' '}
                        <br />
                        대한민국 사회의 실버 산업에 뛰어들어, <br />
                        시골 마을어르신들의 디지털 접근성 향상과 <br />
                        사고 위험예방을 위한 <br />
                        디지털 실버 서비스 제공 중
                    </p> */}

                    <ul>
                        <li>
                            시골 마을 어르신들의 사고 위험 예방을 위한{' '}
                            <br className="mobile" />
                            부녀회장 중심 마을 주민 소통 어플리케이션
                        </li>
                        <li>
                            초고령화 시대로 접어드는 <br className="mobile" />
                            대한민국 사회의 실버 산업에 뛰어들어, <br />
                            시골 마을어르신들의 디지털 접근성 향상과 <br />
                            사고 위험예방을 위한 <br />
                            디지털 실버 서비스 제공 중
                        </li>
                    </ul>
                </RightCon>
                <ImgBoxTown>
                    <img src={Town} alt="" />
                </ImgBoxTown>
            </FlexibleBox>
        </Inner>
    );
};

export default BusinessTwoView;

const Inner = styled.div`
    max-width: 1200px;
    @media screen and (max-width: 786px) {
        padding: 1rem;
        padding-top: 90px;
        height: 100%;
        width: 100%;
    }
`;
const FlexibleBox = styled.div`
    display: flex;
    gap: 4rem;
    align-items: center;
    img {
        max-width: 25rem;
    }
    div p:first-child {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    div p:last-child {
        font-size: 1.2rem;
        line-height: 1.4;
    }
    @media screen and (max-width: 786px) {
        flex-direction: column;
        img {
            position: absolute;
            right: 1rem;
            bottom: 1rem;
            z-index: -1;
            max-width: 10rem;
            opacity: 0.5;
        }
    }
`;
const RightCon = styled.div`
    padding: 1.5rem 2rem;
    border-radius: 1rem;
    box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.4);
    border-radius: 0.8rem;
    p {
        text-align: left;
    }
    ul {
        li {
            font-size: 1.1rem;
            :first-child {
                margin-bottom: 6px;
                font-weight: 500;
            }
        }
    }
    @media screen and (max-width: 786px) {
        position: relative;
        z-index: 1;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 2rem 0;
        .mobile {
            display: none;
        }
        ul {
            li {
                .mobile {
                    display: block;
                }
            }
        }
    }
`;
const ImgBoxTown = styled.div`
    display: flex;
    max-width: 18rem;
    img {
        width: 100%;
        border-radius: 0.8rem;
        box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.4);
    }
`;
const Bg = styled.img`
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translateY(-40%);
    opacity: 0.2;
    @media screen and (max-width: 786px) {
        display: none;
    }
`;
