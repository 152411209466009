import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { userContents } from '../userContents';

export interface IUserContents {
    name: string;
    grade: string;
    role?: string;
    img: string;
    id: string;
    isSelected?: boolean;
}
const Item = ({ name, grade, role, img, id }: IUserContents) => {
    return (
        <Box layoutId={id} className={id}>
            <Link to={`/${id}`}>
                <p>
                    <span>{grade}</span>
                    <span>{name}</span>
                </p>
                <img src={img} alt="" />
            </Link>
        </Box>
    );
};

const List = ({ selectedId }: { selectedId: string }) => {
    return (
        <Grid>
            {userContents.map((item: IUserContents) => (
                <Item
                    key={item.id}
                    {...item}
                    isSelected={item.id === selectedId}
                />
            ))}
        </Grid>
    );
};

export default List;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 1100px;
    gap: 10px;
    margin-bottom: 15rem;
    @media screen and (max-width: 1099px) {
        width: 100%;
        padding: 2rem;
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 786px) {
        margin-bottom: 0;
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Box = styled(motion.div)`
    padding: 2rem 2rem 0;
    position: relative;
    height: 230px;
    background-color: #333;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.06);
    overflow: hidden;
    z-index: 11;
    a {
        display: flex;
        width: 100%;
        img {
            width: 100%;
            transform: translateY(5px);
        }
    }
    p {
        color: ${(props) => props.theme.bgColor};
        position: absolute;
        bottom: 1rem;
        left: 0;
        text-align: center;
        width: 100%;
        z-index: 11;
    }
    p span {
        margin-left: 12px;
        font-size: 1.2rem;
    }
    p span:first-child {
        font-size: 1rem;
    }
    p span:last-child {
        font-weight: 700;
    }
    @media screen and (max-width: 786px) {
        height: 130px;
        a {
            align-items: center;
            justify-content: center;
            img {
                width: auto;
                height: 100%;
            }
        }
    }
`;
