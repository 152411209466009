import styled from 'styled-components';
import imgItem from '../assets/img/pc_monitor.png';

const BusinessView = () => {
    return (
        <Inner>
            <FlexibleBox>
                <ImgBox>
                    <img src={imgItem} alt="" />
                </ImgBox>
                <div>
                    <p>SI 개발 사업</p>
                    <p>
                        Android, iOS, PC Web, Mobile Web 등을 주로 개발하며,
                        기획 단계부터 서비스 오픈 까지 고객과 계속적인
                        커뮤니케이션을 통해 고객의 프로젝트를 성공할 수 있도록
                        서포트를 하고 있습니다.
                    </p>
                    <ListFlexBox>
                        <li>
                            제작 협의 및 결정
                            <ul>
                                <li>상담</li>
                                <li>기능명세 파악</li>
                                <li>견적서 전달</li>
                                <li>계약</li>
                            </ul>
                            <span>01</span>
                        </li>
                        <li>
                            기획 및 디자인
                            <ul>
                                <li>스토리보드 제작</li>
                                <li>기능명세 기반 기획</li>
                                <li>디자인 시안 제작</li>
                            </ul>
                            <span>02</span>
                        </li>
                        <li>
                            설계 및 계발
                            <ul>
                                <li>DB 설계</li>
                                <li>UI 구현</li>
                                <li>Backend 구현</li>
                                <li>Frontend 구현</li>
                            </ul>
                            <span>03</span>
                        </li>
                        <li>
                            검수 및 배포
                            <ul>
                                <li>내부 검수</li>
                                <li>고객 검수</li>
                                <li>수정</li>
                                <li>배포</li>
                                <li>유지보수</li>
                            </ul>
                            <span>04</span>
                        </li>
                    </ListFlexBox>
                </div>
            </FlexibleBox>
            <Bg src={imgItem} />
        </Inner>
    );
};

export default BusinessView;

const FlexibleBox = styled.div`
    display: flex;
    gap: 2rem;
    div {
        z-index: 1;
    }
    img {
        max-width: 20rem;
    }
    div p:first-child {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    div p:last-child {
        font-size: 1rem;
        line-height: 1.4;
    }
`;

const Inner = styled.div`
    max-width: 1200px;
    @media screen and (max-width: 786px) {
        padding: 1rem;
        padding-top: 90px;
    }
`;

const ImgBox = styled.div`
    display: flex;
    img {
        border-radius: 0.8rem;
        box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.4);
    }
    @media screen and (max-width: 786px) {
        display: none;
    }
`;

const ImgBoxTown = styled.div`
    display: flex;
    max-width: 15rem;
    position: absolute;
    bottom: -2rem;
    right: 4rem;
    img {
        width: 100%;
        border-radius: 0.8rem;
        box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.4);
    }
`;

const ListFlexBox = styled.ul`
    margin-top: 3rem;
    display: flex;
    gap: 1rem;
    > li {
        padding: 2rem 1.3rem 3rem;
        flex: 1;
        font-weight: bold;
        background: rgba(255, 255, 255, 0.18);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 0.8rem;
        position: relative;
    }
    > li span {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        color: rgba(125, 125, 125, 0.3);
        font-size: 3rem;
    }
    ul {
        list-style: disc;
        padding-left: 1.5rem;
        margin-top: 0.5rem;
    }
    li > ul > li {
        font-size: 0.8rem;
        font-weight: 400;
    }
    @media screen and (max-width: 786px) {
        flex-wrap: wrap;
        > li {
            flex: 1 1 45%;
        }
    }
`;

const RightCon = styled.div`
    p {
        text-align: right;
    }
`;
const Bg = styled.img`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-40%);
    opacity: 0.2;
`;
