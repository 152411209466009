import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import styled from 'styled-components';
import List from '../components/List';
import { useParams } from 'react-router-dom';
import MansModal from '../components/MansModal';
import mamberBg from '../assets/img/mamberBg.png';

interface IUserContents {
    id: string;
}

export const MansView = () => {
    const [selectId, setSelectId] = useState<null | string>(null);
    const { id } = useParams<IUserContents>();
    return (
        <Wrapper>
            <List selectedId={id !== undefined ? id : ''} />
            <AnimatePresence initial={false}>
                {id && <MansModal id={id} />}
            </AnimatePresence>
            <Bg src={mamberBg} />
        </Wrapper>
    );
};

const Wrapper = styled(motion.div)`
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    padding-top: 90px;
`;

const Bg = styled.img`
    position: absolute;
    left: 50%;
    bottom: -20%;
    transform: translateX(-50%);
    opacity: 0.2;
`;
