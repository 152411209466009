import styled from 'styled-components';
import Logo from '../components/Logo';
import LogoSe from '../components/LogoSe';
import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import LogoTest from '../components/LogoTest';

const Wrap = styled.div``;

const Section = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Main = () => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        setTimeout(() => setIsOpen(true), 5000);
    });
    return (
        <Wrap>
            <Section>행복한 개발자들의 공간입니다.</Section>
        </Wrap>
    );
};

export default Main;
